import React, { Fragment, useState, useEffect } from "react";
import { collection, getDoc, setDoc, doc } from "firebase/firestore";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { db } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardModal from "./CardModal";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import logo from "../assets/tsg-logo-tra.png";
import { storage } from "../firebase"; // Import Firebase Storage
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import imageCompression from "browser-image-compression"; // Import compression library

const Admin = () => {
  const { logout } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [newInfoForm, setnewInfoForm] = useState({
    labelImages: [], // Store multiple images
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const disabledButton = newInfoForm.labelImages.length === 0;

  const handleChange = (e, upload) => {
    const { name, files } = e.target;
    setnewInfoForm((prev) => ({
      ...prev,
      [name]: Array.from(files), // Handle multiple files
    }));
  };

  const clearForm = () => {
    document.getElementById("labelImages").value = "";
    setnewInfoForm({ labelImages: [] }); // Clear the files array
  };

  const clearMinorForm = () => {
    setnewInfoForm({ labelImages: [] }); // Clear the files array
  };

  const saveCards = async () => {
    setIsLoading(true);

    try {
      const uploadPromises = newInfoForm.labelImages.map(async (image) => {
        // Compress each image before uploading
        const options = {
          maxSizeMB: 0.3, // Set to 300 KB
          maxWidthOrHeight: 600, // Max dimensions
          useWebWorker: true,
        };

        const compressedImage = await imageCompression(image, options);

        const storageRef = ref(storage, `labels/${compressedImage.name}`);
        const uploadResult = await uploadBytes(storageRef, compressedImage);
        const downloadURL = await getDownloadURL(uploadResult.ref);

        const customDocRef = doc(db, "gradedCards", compressedImage.name.split('.')[0]); // Use file name as doc ID
        const existingDoc = await getDoc(customDocRef);

        if (existingDoc.exists()) {
          toast.error(
            `ERROR: Card with serial number ${compressedImage.name.split('.')[0]} already exists in the database!`
          );
        } else {
          await setDoc(customDocRef, {
            serialNumber: compressedImage.name.split('.')[0], // Use the ID from the file name
            labelImage: downloadURL,
          });
          toast.success("SUCCESS: Graded Card added to DB!");
        }
      });

      // Wait for all uploads to complete
      await Promise.all(uploadPromises);
      clearMinorForm();
    } catch (error) {
      console.error("Error saving cards:", error);
      toast.error("Failed to save the cards. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      {isLoading ? (
        <div className="loading-screen">
          <h1>Saving...</h1>
        </div>
      ) : (
        <div className="main-content">
          <div className="form-head">
            <img className="mb-3 logo-tcg" src={logo} width="120" alt="Logo" />
            <h3 className="headyc" id="header" data-text="TSG Database Form">
              <span>Card Database Form</span>
              <span className="linksp" onClick={openModal}>
                View User Submissions
              </span>
            </h3>
            <div onClick={() => logout({ returnTo: window.location.origin })}>
              Logout
            </div>
          </div>
          <form>
            <div className="form1">
              <div className="form-group mt-3">
                <label htmlFor="labelImages">Upload Label Images</label>
                <input
                  type="file"
                  id="labelImages"
                  name="labelImages"
                  className="form-control"
                  onChange={(e) => handleChange(e, true)}
                  multiple // Allow multiple file selection
                  required
                />
              </div>
            </div>
            <div className="btncontainer">
              <Button
                id="qsCancelBtn"
                color="primary"
                block
                onClick={clearForm}
              >
                Clear
              </Button>
              <Button
                id="qsLoginBtn"
                color="primary"
                disabled={disabledButton}
                block
                onClick={saveCards}
              >
                Save All
              </Button>
            </div>
          </form>
        </div>
      )}
      <ToastContainer autoClose={5000} />
      <CardModal isOpen={isModalOpen} onRequestClose={closeModal} />
    </Fragment>
  );
};

export default withAuthenticationRequired(Admin, {
  onRedirecting: () => <Loading />,
});
